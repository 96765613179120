<template>
  <form-field :name="field.name">
    <v-text-field
      v-model="input"
      :counter="counter"
      :mask="mask"
      :prepend-icon="prependIcon"
      v-bind="field.props"
      clearable
      box
      validate-on-blur
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  data () {
    return {
      counter: null,
      input: null,
      longLandMask: '## #### ####',
      longMask: '#### ### ####',
      shortLandMask: '## ### ####',
      shortMask: '### ### ####',
    }
  },
  computed: {
    mask: function () {
      if (!this.input) {
        return this.shortLandMask
      }

      const mask = this.input.startsWith('011') ? this.longMask : this.input.startsWith('01')
        ? this.shortMask : this.input.startsWith('03') ? this.longLandMask
          : this.input.startsWith('00') || this.input.startsWith('02')
            ? '03 #### ####' : (!this.input.startsWith('0') ||
          (!this.input.startsWith('01') && this.input.length > 1))
              ? '0# ### ####' : this.shortLandMask

      return mask
    },
    prependIcon: function () {
      return this.input && this.input.startsWith('01') ? 'phonelink_ring' : 'phone'
    },
  },
  watch: {
    mask: function (val) {
      if (!this.input) {
        this.counter = 9
      }

      this.counter = this.mask === this.longMask ? 11 : this.mask === this.longLandMask ||
        this.mask === this.shortMask ? 10 : 9
    },
    input: function (val) {
      this.field.value = val
    },
  },
  mounted: function () {
    this.field.props.rules.push(val => !val || (val && val.length === this.counter) || 'Invalid phone number!')
  },
}
</script>
